$(document).on('turbolinks:load', function() {

  // install event handler for expanding individual vendors
  const expanders = $('[id^=vendorExpander]');
  expanders.click(function(event) {
    event = event || window.event;
    const expander_icon = event.target || event.srcElement;
    const expander = $(expander_icon).closest('[id^=vendorExpander]');
    const expander_id = $(expander).attr('id');
    const expander_id_parts = expander_id.split('_');
    const vendor_id = expander_id_parts[1];

    // expand or collapse the clicked element
    $('#customFields_' + vendor_id).toggle();
    $('#promoterNotes_' + vendor_id).toggle();

    // check to see if all expanders are expanded or collapsed
    const collapsed_expanders = $('[id^=promoterNotes_]:hidden');
    const expanded_expanders = $('[id^=promoterNotes_]:visible');
    if (collapsed_expanders.length === 0) {
      // all are expanded, make sure the mass expander is a mass collapser
      $('#massExpander').text('Collapse All');
    }

    if (expanded_expanders.length === 0) {
      // all are collapsed, make sure the mass expander is a mass expander
      $('#massExpander').text('Expand All');
    }

  });

  // install event handler for expanding/collapsing all vendors
  const mass_expander = $('#massExpander');
  mass_expander.click(function(event) {
    event = event || window.event;
    const expander = event.target || event.srcElement;
    const current_text = $(expander).text();
    if (current_text === 'Expand All') {
      $('[id^=customFields_]').show();
      $('[id^=promoterNotes_]').show();
      $(expander).text('Collapse All');
    } else {
      $('[id^=customFields_]').hide();
      $('[id^=promoterNotes_]').hide();
      $(expander).text('Expand All');
    }
    return false;
  });

});
