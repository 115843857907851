import Rails from '@rails/ujs';

$(document).on('turbolinks:load', function() {

  if ($('body.registrations').length > 0 || $('body.consumer-site').length > 0) {
    initializeRecaptcha();
  }

  // promoter registration form
  $('#promoterRegistrationForm input[type=submit]').click(function(e) {
    e.preventDefault();
    submitRecaptchableForm('promoterRegistrationForm',
                           'recaptchaTokenField',
                           'signup');
    return false;
  });

  // contact request forms on promoter websites
  $('#contactRequestForm input[type=submit]').click(function(e) {
    e.preventDefault();
    submitRecaptchableForm('contactRequestForm',
                           'recaptchaTokenField',
                           'contactRequest');
    return false;
  });
});

function initializeRecaptcha() {
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://www.google.com/recaptcha/api.js?render=6LeYQ88UAAAAABqBVUoi6I2kUXCcIxcScSfVWhQj";
  document.getElementsByTagName("head")[0].appendChild(script);
  return false;
}

function submitRecaptchableForm(form_id, token_field_id, action) {
  grecaptcha.ready(function() {
    grecaptcha.execute('6LeYQ88UAAAAABqBVUoi6I2kUXCcIxcScSfVWhQj', { action: action }).then(function(token) {
      // stuff the form's hidden, recaptcha token field
      document.getElementById(token_field_id).value = token;
      // submit the form
      var recaptchaedForm = document.querySelector('#' + form_id);
      var isRemote = $(recaptchaedForm).data('remote');
      if (isRemote) {
        Rails.fire(recaptchaedForm, 'submit');
      }
      else {
        recaptchaedForm.submit();
      }
    });
  });
}
