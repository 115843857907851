// constants

const addButtonId         = 'boothsAddbutton';
const boothDialogId       = 'boothsDialogForm';
const boothIdDataKey      = 'boothid';
const boothIdFieldId      = 'boothId';
const boothNameDataKey    = 'boothname';
const boothNameFieldId    = 'boothsNameField';
const boothPathIdPrefix   = 'boothsPath';
const boothTypeDataKey    = 'boothtype';
const boothTypeSelectorId = 'boothType';
const cancelButtonId      = 'boothsCancelButton';
const clickableClass      = 'booths-clickable';
const deleteButtonId      = 'boothsDeleteButton';
const imageWrapperId      = 'boothsImageWrapper';
const moveDownButtonId    = 'boothsMoveDownButton';
const moveLeftButtonId    = 'boothsMoveLeftButton';
const moveRightButtonId   = 'boothsMoveRightButton';
const moveUpButtonId      = 'boothsMoveUpButton';
const verticesFieldId     = 'vertices';
const vertexMarkerClass   = 'vertexMarker';


// attributes

let   showeasyAddingBooth = false;
const vertices            = [];


// initialization

$(document).on('turbolinks:load', function() {
  if ($('#' + boothDialogId).length > 0) {
    cancelBoothAdd();

    $('#' + addButtonId).click(function(event) {
      event.preventDefault();
      event.stopPropagation();

      startBoothAdd();
    });

    $('#' + cancelButtonId).click(function(event) {
      event.preventDefault();
      event.stopPropagation();

      cancelBoothAdd();
    });

    $('#' + moveDownButtonId).click(function(event) {
      event.preventDefault();
      event.stopPropagation();

      moveBoothDialog('bottom', 'top', '8vh');
    });

    $('#' + moveLeftButtonId).click(function(event) {
      event.preventDefault();
      event.stopPropagation();

      moveBoothDialog('left', 'right', '8vw');
    });

    $('#' + moveRightButtonId).click(function(event) {
      event.preventDefault();
      event.stopPropagation();

      moveBoothDialog('right', 'left', '8vw');
    });

    $('#' + moveUpButtonId).click(function(event) {
      event.preventDefault();
      event.stopPropagation();

      moveBoothDialog('top', 'bottom', '8vh');
    });

    $('.' + clickableClass).click(function(event) {
      event = event || window.event;
      var triggeringPath = event.target || event.srcElement;

      startBoothEdit(triggeringPath);
    });
  }
});


// methods

function cancelBoothAdd() {
  $('#' + imageWrapperId).off('click', rememberVertex);
  $('#' + imageWrapperId).css('cursor', 'default');
  $('.' + vertexMarkerClass).remove();

  $('#' + boothDialogId).hide();

  $('#' + cancelButtonId).hide();
  $('path[id^="' + boothPathIdPrefix + '"]').removeClass("selected");
  $('path[id^="' + boothPathIdPrefix + '"]').addClass(clickableClass);
  $('#' + addButtonId).removeClass("disabled");

  showeasyAddingBooth = false;

  // TODO - reinstall the startBoothEdit event handler
}

function rememberVertex(event) {
  const divWidth = $(this).width();
  const divHeight = $(this).height();
  const posX = event.pageX - ($(this).offset().left);
  const posY = event.pageY - ($(this).offset().top);

  const vertex = [
    posX / divWidth,
    posY / divHeight
  ];
  vertices.push(vertex);

  const verticesFieldId = event.data.vertices_field_id;
  const imageWrapperId  = event.data.image_wrapper_id;

  // TODO - maybe make size and color of vertex marker user configurable
  $('input#' + verticesFieldId).attr('value', JSON.stringify(vertices));
  let vertexMarker =  "<svg class='" + vertexMarkerClass + "' height='100%' width='100%' viewBox'0 0 100 100' ";
  vertexMarker += "     preserveAspectRatio='none'>";
  vertexMarker += "  <rect x='" + (posX - 5) + "' y='" + (posY - 5) + "' width='10.0' height='10.0' />";
  vertexMarker += "</svg>";
  $('#' + imageWrapperId).append(vertexMarker);
};

function startBoothAdd() {
  // TODO - remove the startBoothEdit event handler

  vertices.length = 0; // clear the verticies array
  showeasyAddingBooth = true;

  $('#' + addButtonId).addClass("disabled");
  $('path[id^="' + boothPathIdPrefix + '"]').removeClass(clickableClass);
  $('#' + cancelButtonId).show();

  $('input#' + boothIdFieldId).attr('value', '');
  $('input#' + boothNameFieldId).attr('value', '');
  $('input#' + verticesFieldId).attr('value', '');
  $('#' + deleteButtonId).hide();
  $('#' + boothDialogId).show();

  const data_hash = { vertices_field_id: verticesFieldId, image_wrapper_id: imageWrapperId };
  $('#' + imageWrapperId).on('click', data_hash, rememberVertex);
  $('#' + imageWrapperId).css('cursor', 'crosshair');
};

function startBoothEdit(triggeringPath) {
  if (!showeasyAddingBooth) {

    var boothId   = $(triggeringPath).data(boothIdDataKey);
    var boothName = $(triggeringPath).data(boothNameDataKey);
    var boothType = $(triggeringPath).data(boothTypeDataKey);

    $('#' + addButtonId).addClass("disabled");
    $('#' + cancelButtonId).show();

    $('input#' + boothIdFieldId).attr('value', boothId);
    $('input#' + boothNameFieldId).attr('value', boothName);
    $('select#' + boothTypeSelectorId + ' > option').removeAttr('selected');
    $('select#' + boothTypeSelectorId + ' > option[value=' + boothType + ']').attr('selected', true);
    $('select#' + boothTypeSelectorId).val(boothType);
    $('input#' + verticesFieldId).attr('value', '');
    $('#' + deleteButtonId).show();
    $('#' + boothDialogId).show();

    $('path[id^="' + boothPathIdPrefix + '"]').removeClass("selected");
    $('path#' + boothPathIdPrefix + boothId.toString()).addClass("selected");
    return;
  }
};

function moveBoothDialog(styleToSet, styleToClear, position) {
  $('#' + boothDialogId).css(styleToSet, position);
  $('#' + boothDialogId).css(styleToClear, "auto");
};
