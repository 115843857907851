// assign_booths.js.erb
// JS to support the assign_booths modal used from the vendors form
// --------------------------------------------------------------------------------------------------------------------

// constants

const boothPathPrefixId     = 'boothPathSelector';
const modalId               = 'boothSelectorModal';
const noSelectedBoothsText  = 'No booth(s) assigned';
const selectedBoothsFieldId = 'showEasySelectedBooths';


// attributes

let selectedBooths;


// initialization

$(document).on('turbolinks:load', function() {
  $('.venue-modal.booth-selector').on('shown.bs.modal', function() {
    initializeAssignBooths();
  });
});


// methods

function initializeAssignBooths() {
  selectedBoothsHiddenFieldId = $('#doneButton').data('hidden');
  selectedBoothsDisplaySpanId = $('#doneButton').data('span');
  selectedBooths = JSON.parse($('#' + selectedBoothsHiddenFieldId).attr('value'))
  updateDisplay();

  $('#doneButton').click(function (event) {
    var selectedBoothNames = $('#' + selectedBoothsFieldId).html();

    if (selectedBoothNames === noSelectedBoothsText) {
      selectedBoothNames = null;
    }

    $('#' + modalId).modal('hide');
    $('span#' + selectedBoothsDisplaySpanId).html(selectedBoothNames);
    $('input#' + selectedBoothsHiddenFieldId).attr('value', JSON.stringify(selectedBooths));
    event.preventDefault();
  });

  $('[id^=' + boothPathPrefixId + ']').click(function (event) {
    event = event || window.event;
    var clickedBoothSvg = event.target || event.srcElement;

    selectBooth(clickedBoothSvg);
  });
}

function updateDisplay() {
  let booth;
  if (selectedBooths.length > 0) {
    let selectedBoothNames = "";
    let first = true;
    for (const booth of selectedBooths) {
      if (!first) {
        selectedBoothNames += ", ";
      }
      selectedBoothNames += booth.name;
      first = false;
    }
    $('p#' + selectedBoothsFieldId).html(selectedBoothNames);
  } else {
    $('p#' + selectedBoothsFieldId).html(noSelectedBoothsText);
  }

  $('path[id^=' + boothPathPrefixId + ']').removeClass("selected");
  for (const booth of selectedBooths) {
    $('path#' + boothPathPrefixId + booth.id.toString()).addClass("selected");
  }
}

function selectBooth(clickedBoothSvg) {
  let boothId = $(clickedBoothSvg).data('booth-id');
  let boothName = $(clickedBoothSvg).data('booth-name');

  let i = 0;
  let iToRemove = -1;
  while ((i < selectedBooths.length) && (iToRemove < 0)) {
    if (selectedBooths[i].id == boothId) {
      iToRemove = i;
    }
    i++;
  }
  if (iToRemove >= 0) {
    selectedBooths.splice(iToRemove, 1);
  } else {
    selectedBooths.push({ id: boothId, name: boothName });
  }
  updateDisplay();
}
