/*
 * showeasy::tinymce
 */

import "tinymce";
import "tinymce/icons/default";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/themes/silver";

$(document).on('turbolinks:load', function() {
  tinymce.init({
    selector: '.tinymce',
    content_css: false,
    skin: false,
    menubar: false,
    plugins: [ 'lists link' ],
    toolbar: 'styleselect | ' +
      'bold italic underline | ' +
      'bullist numlist alignleft aligncenter alignright | ' +
      'link | ' +
      'undo redo | ' +
      'removeformat',
    style_formats: [
      { title: 'Normal text', block: 'p'  },
      { title: 'Heading 1',   block: 'h1' },
      { title: 'Heading 2',   block: 'h2' },
      { title: 'Heading 3',   block: 'h3' }
    ]
  });
});

// without this, sometimes tiny text areas don't initialize properly
// ref: https://github.com/spohlenz/tinymce-rails/issues/145
$(document).on('turbolinks:request-end', function() {
  if (tinymce) { tinymce.remove(); }
});
