$(document).on('turbolinks:load', function() {
  // initialize the selected value's color to match the corresponding option's color
  let selector = $('#copyMoveSelector');
  let color = $('option:selected', selector).css('color');
  $(selector).css('color', color);

  // install an event handler to update the selected value's color when it is changed
  selector.change(function(event) {
    event = event || window.event;
    selector = event.target || event.srcElement;
    color = $('option:selected', this).css('color');
    $(selector).css('color', color);
  });
});
