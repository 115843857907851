$(document).on('turbolinks:load', function() {

  $.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
            icons: {
                time: 'far fa-clock',
                date: 'fas fa-calendar',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-check',
                clear: 'fas fa-trash',
                close: 'fas fa-times'
            } });

  $('#dateTimePickerPayment').datetimepicker({ format: 'MM/DD/YYYY' });
  $('body.admin.shows [id^=dateTimePicker]').datetimepicker();
  $('#dateTimePickerSendAt').datetimepicker();
});
